import { render, staticRenderFns } from "./MemberNotice.vue?vue&type=template&id=e392d728&scoped=true"
import script from "./MemberNotice.vue?vue&type=script&lang=js"
export * from "./MemberNotice.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e392d728",
  null
  
)

component.options.__file = "MemberNotice.vue"
export default component.exports